<template>
  <section class="scope-box" :style="{ height: activeKey === 'staff' ? '437px' : '349px' }">
    <section v-show="activeKey === 'staff'" class="scoped-head">
      <a-input
        class="search-input"
        :style="{ width: types === 'add' ? '339px' : '190px' }"
        v-model:value="check.searchLbwVal"
        placeholder="请输入人员查询"
      >
        <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
      </a-input>
      <a-button type="primary" class="btn" @click="searchStaffList">查询</a-button>
    </section>
    <section v-show="activeKey === 'department'" class="scoped-head">
      <a-input
        class="search-input"
        :style="{ width: types === 'add' ? '339px' : '190px' }"
        v-model:value="searchDept"
        placeholder="请输入部门查询"
      >
        <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
      </a-input>
      <a-button type="primary" class="btn" @click="searchDepartment">查询</a-button>
    </section>
    <section class="scope-content">
      <a-tabs v-model:activeKey="activeKey" type="card">
        <a-tab-pane key="staff" tab="人员">
          <div class="staff-list" v-show="check.searchLbwList.length">
            <a-checkbox :indeterminate="check.indeterminate" :checked="check.checkAll" @change="onCheckAllChange">
              全选（{{ check.checkedList.length }}）
            </a-checkbox>
            <a-checkbox-group :value="check.checkedList">
              <virtual-list :list="check.searchLbwList" :size="38" :remain="6" :isScrollTop="isVirtualListScroll">
                <template #default="{ item, index }">
                  <approval-staff-item :item="item" :index="index" :onChange="onChange" :isProcess="true" />
                </template>
              </virtual-list>
            </a-checkbox-group>
          </div>
          <div v-show="check.searchLbwList.length === 0"  style="line-height: 204px;text-align: center;padding:0；background-color: #f7f8fa;">暂无数据</div>
        </a-tab-pane>
        <a-tab-pane key="department" tab="部门">
          <section class="department-list" v-if="organizationalList.length">
            <tree-virtual-list
              @onCheck="onCheck"
              :size="32.3"
              :remain="organizationalList.length"
              :list="organizationalList"
              :isShow="2"
              v-model:department="department"
              :isScrollTop="isScrollTop"
            />
          </section>
          <div v-else class="department-list" style="line-height: 204px;text-align: center;padding:0">暂无数据</div>
        </a-tab-pane>
      </a-tabs>
    </section>
  </section>
</template>

<script>
import { defineComponent, reactive, toRaw, toRefs, watch } from 'vue'
import VirtualList from '@/components/VirtualList'
import ApprovalStaffItem from '@/components/VirtualList/approvalStaffItem'
import { useCheckStateAndEvent } from '@/utils/hooks'
import TreeVirtualList from '@/components/VirtualList/treeVirtualList'

export default defineComponent({
  name: 'scope-tab',
  components: {
    VirtualList,
    ApprovalStaffItem,
    TreeVirtualList
  },
  props: {
    searchLbwList: {
      type: Array,
      default: () => []
    },
    departmentVal: {
      type: Object,
      defalut: () => {}
    },
    department: {
      default: undefined
    },
    visible: {
      type: Boolean,
      default: false
    },
    types: {
      type: String,
      default: 'add'
    },
    organizationalList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    console.log('scoope tab monted')
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      activeKey: 'staff',
      department: [],
      departMap: new Map(),
      autoExpandParent: false,
      expandedKeys: [],
      departmentSearchList: [],
      isVirtualListScroll: 0,
      isScrollTop: 0,
      // 搜索部门关键字
      searchDept: ''
    })
    const searchDepartment = () => {
      emit('searchDepartment', state.searchDept)
    }

    const enhancerMapFn = {
      onCheckAllChange() {
        emit('update:departmentVal', { ...props.departmentVal, staff: toRaw(check.checkedList) })
        emit('update:department', !check.checkedList.length && !state.department.length ? undefined : '已选择')
      },
      onChange() {
        emit('update:departmentVal', { ...props.departmentVal, staff: check.checkedList })
        emit('update:department', !check.checkedList.length && !state.department.length ? undefined : '已选择')
      }
    }
    const { check, onChange, onCheckAllChange, reloadSearchLbwList } = useCheckStateAndEvent(enhancerMapFn)

    const onExpand = expandedKeys => {
      state.expandedKeys = expandedKeys
      state.autoExpandParent = false
    }
    const onCheck = department => {
      state.department = department
      emit('update:departmentVal', { ...props.departmentVal, department })
      emit('update:department', !check.checkedList.length && !department.length ? undefined : '已选择')
    }
    const searchStaffList = () => {
      check.searchLbwList = check.searchLbwVal
        ? props.searchLbwList.filter(item => item.name.includes(check.searchLbwVal))
        : props.searchLbwList
      check.checkedList = []
      props.searchLbwList.forEach(item => {
        check.checkedMap[item.value] ? check.checkedList.push(item.value) : (check.checkedMap[item.value] = false)
      })
      // check.checkedList = []
      // check.checkAll = check.indeterminate = false
      typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
    }

    watch(
      () => {
        props.organizationalList.length
        },
      () => {
        state.departMap.clear()
        function deepIteration(array) {
          array.forEach(item => {
            if (item.children && item.children.length) {
              deepIteration(item.children)
            }
            state.departMap.set(item.key, false)
          })
        }

        deepIteration(props.organizationalList)
      },
      { immediate: true }
    )

    watch(
      () => props.searchLbwList.length,
      () => {
        check.searchLbwList = props.searchLbwList.map(item => item)
        check.totalSearchList = props.searchLbwList.map(item => item)
      },
      { immediate: true }
    )

    watch(
      () => props.visible,
      newVisible => {
        if (!newVisible) return
        state.activeKey = 'staff'
        state.expandedKeys = ['sss']
        state.autoExpandParent = false
        check.searchLbwVal = undefined
        check.checkedMap = {}
        if (props.types === 'add') {
          state.department = []
          check.checkAll = false
          check.indeterminate = false
          check.checkedList = []
        } else if (props.types === 'edit') {
          state.department = props.departmentVal.department.length ? [...props.departmentVal.department] : []
          check.checkedList = props.departmentVal.staff.map(item => {
            check.checkedMap[item] = true
            return item
          })
          check.checkAll = props.departmentVal.staff.length === check.searchLbwList.length
          check.indeterminate = !check.checkAll&&props.departmentVal.staff.length>0
          emit('update:department', !check.checkedList.length && !state.department.length ? undefined : '已选择')
        }
        reloadSearchLbwList()
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
        state.isScrollTop++
      },
      { immediate: true }
    )

    return {
      ...toRefs(state),
      onCheckAllChange,
      onChange,
      check,
      onExpand,
      onCheck,
      searchStaffList,
      searchDepartment
    }
  }
})
</script>

<style lang="scss" scoped>
.scope-box {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  .scoped-head {
    padding: 24px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-between;
    .search-input {
      width: 190px;
    }
    .btn {
      // margin-left: 16px;
      border-radius: 2px;
    }
  }
  .scope-content {
    padding: 16px 24px;
    :deep(.ant-tabs-bar) {
      margin-bottom: 0;
    }
    .staff-list {
      padding: 16px 0 16px 16px;
      height: 292px;
      overflow: hidden;
      overflow-y: auto;
      background-color: #f7f8fa;
    }
    .department-list {
      padding: 0 0 10px 9px;
      height: 204px;
      overflow: hidden;
      overflow-y: scroll;
      background-color: #f7f8fa;
    }
    :deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container),
    :deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab) {
      height: 32px;
      line-height: 32px;
    }
    :deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab) {
      padding: 0 30px;
      border-radius: 4px 4px 0 0;
    }
    :deep(.ant-tabs) {
      color: #666666;
    }
    :deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab) {
      background-color: #ffffff;
    }
  }
}
</style>
